<template>
  <div
    class="slider"
  >
   <div class="slider__container container">
     <h3 class="slider__title" v-text="title" />
     <div class="slider__row"> <!--         :pagination="{ clickable: true }"-->
       <swiper
         class="slider__swiper"
         :slides-per-view="1"
         :space-between="0"
         :navigation="false"
         loop
         :pagination="pagination"
         @swiper="onSwiper"
         @slideChange="onSlideChange"
       >
         <swiper-slide
           v-for="slide in testimonials"
           :key="slide.id"
           :virtualIndex="slide.id"
           class="slider__item slide"
         >
           <div class="slide__content">
             <blockquote class="slide__text" v-text="slide.text" />
             <h4 class="slide__name" v-text="slide.name" />
             <div class="slide__position" v-text="slide.position" />
           </div>
         </swiper-slide>
       </swiper>
     </div>
   </div>
  </div>
</template>

<script>
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import sliderImage from '@/assets/images/slide-image.png';
import user1 from '@/assets/images/user-1.png';
import user2 from '@/assets/images/user-2.png';
import user3 from '@/assets/images/user-3.png';

require('swiper/swiper.scss');

require('swiper/components/pagination/pagination.scss');
// install Swiper modules
SwiperCore.use([Pagination]);

export default {
  name: 'AgentsTestimonials',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sliderImage,
      pagination: {
        clickable: true,
        renderBullet: (index, className) => `<div class="${className}"><img src="${this.testimonials[index].avatar}" /></div>`,
      },
      title: 'See what our agents say about us.',
      testimonials: [
        {
          id: 1,
          avatar: user1,
          name: '1Ashley Meg',
          position: 'Web Developer at Facebook',
          text: 'Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 2,
          avatar: user2,
          name: '2Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '" sum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dol Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 3,
          avatar: user3,
          name: '3Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 4,
          avatar: user3,
          name: '4Ashley Meg',
          position: 'Web Developer at Facebook',
          text: 'Our platformsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dol was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 5,
          avatar: user3,
          name: '5Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.slider {
  padding: 70px 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
  }

  @include breakpoint(0, $md) {
    padding: 25px 0;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;

    @include breakpoint(0, $lg) {
      font-size: 18px;
    }

    @include breakpoint(0, $lg) {
      font-size: 16px;
    }
  }

  &__row {
    max-width: 910px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__swiper {
    width: 100%;
  }

  :deep {
    .slider__item {
      padding: 50px;

      @include breakpoint(0, $lg) {
        padding: 40px;
      }

      @include breakpoint(0, $md) {
        padding: 30px;
      }

      .slide__name {
        margin-top: auto;
      }
    }

    .swiper-container {
      background-color: $light;
      box-shadow: 0 3px 24px rgba(0, 0, 0, 0.16);
      margin-bottom: 80px;
      overflow: visible;

      @include breakpoint(0, $lg) {
        margin-bottom: 80px;
      }
    }

    .swiper-slide {
      height: unset;
    }

    .swiper-pagination {
      bottom: -80px;

      @include breakpoint(0, $lg) {
        bottom: -80px;
      }

      .swiper-pagination-bullet {
        background-color: transparent;
        border: 0;
        opacity: 1;

        @include image-box;
        min-height: 60px;
        width: 60px;
        height: 60px;
        border: 5px solid transparent;

        @include breakpoint(0, $lg) {
          min-height: 38px;
          width: 41px;
          height: 41px;
          border: 3px solid transparent;
        }
      }
      .swiper-pagination-bullet-active {
        border-color: $purple-800;
      }
    }

  }

}

.slide {
   &__content {
     display: flex;
     flex-direction: column;
     height: 100%;
   }
   &__text {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 2;
    color: $text;
    font-style: italic;
    font-weight: 700;
    font-family: $secondary-font-family;
  }
  &__name {
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 1.33;
    color: $dark;

    @include breakpoint(0, $lg) {
      font-size: 16px;
    }
  }
  &__position {
    text-align: center;
    font-size: 12px;
    color: $dark;
  }
}
</style>
