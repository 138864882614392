<template>
  <div class="">
    <promo-section
      class="promo--agents"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
    />
    <agents-section />
    <agents-testimonials />
    <image-banner />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import AgentsSection from '@/components/agents-page/AgentsSection.vue';
import AgentsTestimonials from '@/components/agents-page/AgentsTestimonials.vue';
import ImageBanner from '@/components/agents-page/ImageBanner.vue';
import promoImage from '@/assets/images/agents-promo.svg';

export default {
  name: 'Agents',
  components: {
    PromoSection,
    AgentsSection,
    AgentsTestimonials,
    ImageBanner,
  },
  data() {
    return {
      promo: {
        subtitle: 'Agents',
        title: ' <span class="text-accent">Multiple programs</span> to support a wide range of trucking operations',
        image: promoImage,
      },
    };
  },
};
</script>
