<template>
  <div
    class="image-banner"
  >
   <div class="image-banner__container container">
     <div class="image-banner__row">
       <div class="image-banner__image">
         <img :src="bannerImage" alt="">
       </div>
       <div class="image-banner__content">
         <h2 class="image-banner__title" v-html="title" />
         <p class="image-banner__text" v-html="text" />
         <div class="image-banner__actions">
           <main-button :route="true" :link="link" class="image-banner__button btn--bordered">
             GET IN TOUCH
           </main-button>
         </div>
       </div>
      </div>
   </div>
  </div>
</template>

<script>
import bannerImage from '@/assets/images/banner-image.png';

export default {
  name: 'ImageBanner',
  data() {
    return {
      title: 'If you’re looking to get quick, fast quotes for your clients CoverWhale is your new best friend.',
      text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.',
      link: '/contact',
      bannerImage,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.image-banner {
  padding: 70px 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
  }

  @include breakpoint(0, $md) {
    padding: 25px 0;
  }

  &__row {
    position: relative;
    padding: 60px;
    max-width: 1340px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $primary;
    border-radius: $border-radius * 6;

    @include breakpoint(0, $xl) {
      padding: 50px;
    }

    @include breakpoint(0, $lg) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 40px;
    }

    @include breakpoint(0, $md) {
      padding: 30px;
    }
  }

  &__image {
    width: 280px;
    max-width: 100%;
    flex: none;
    margin-right: 100px;

    @include breakpoint(0, $xl) {
      width: 250px;
      margin-right: 0;
    }

    @include breakpoint(0, $lg) {
      margin-right: 0;
    }

    @include breakpoint(0, $md) {
      width: 200px;
    }

    img {
      position: relative;
      bottom: -60px;
      max-width: 100%;

      @include breakpoint(0, $xl) {
        bottom: -50px;
      }

      @include breakpoint(0, $lg) {
        bottom: -40px;
      }

      @include breakpoint(0, $md) {
        bottom: -30px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    color: $light;
    font-size: 40px;
    font-weight: 400;

    @include breakpoint(0, $lg) {
      width: 100%;
      font-size: 36px;
    }

    @include breakpoint(0, $md) {
      font-size: 28px;
    }
  }

  &__text {
    color: $light;
    font-size: 14px;
    margin-bottom: 30px;

    @include breakpoint(0, $lg) {
      display: none;
    }
  }

  &__actions {
    @include breakpoint(0, $lg) {
      text-align: center;
    }
  }

}
</style>
