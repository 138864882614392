<template>
  <div
    class="agents"
    id="agents"
  >
   <div class="agents__container container">
     <div class="agents__row">
       <h2 v-if="title" class="agents__title heading" v-html="title" />
       <div class="agents__text" v-html="text"></div>
      </div>
   </div>
  </div>
</template>

<script>

export default {
  name: 'AgentsSection',
  data() {
    return {
      title: 'Imagine a world, where insurance quotes were given in minutes. Meet CoverWhale.',
      text: `
        <p>This talks about CoverWhale for agents, and how the process is going to make their sales cycles shorter. consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <p> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        `,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.agents {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $sm) {
    padding: 35px 0 25px;
  }

  &__row {
    max-width: 980px;
    margin: 0 auto;
  }

  &__title {
    @include breakpoint(0, $sm) {
      display: none;
    }

  }

  &__text {
    font-size: 14px;
    line-height: 1.7;
    color: $text;

    :deep {
      p {
        position: relative;
        font-size: inherit;
        line-height: inherit;
        color: inherit;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

}

</style>
